import { Link } from 'react-router-dom';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {dracula as codeStyle } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {brands} from './brands'
import { icons } from '../icons';

export const gridClasses = {
    normal:'w-full md:w-6/12 lg:w-4/12',
    expanded:'w-full md:w-full lg:w-8/12'
}


export const oldies = [
    {
        id:'cassio',
        title:'Cassio',
        prev:<div className='bg-red-100 h-[450px] p-20 flex items-center justify-center svg-blue-700 relative'>{brands.cassio}
            <div className='bg-white bg-opacity-60 p-2 rounded-full absolute bottom-0 right-0 m-5'>{icons.clock}</div>
        </div>,
        notExpandable:true,
    },
    {
        id:'tekne1',
        title:'Tekne1',
        prev:<div className='bg-slate-100 p-20 flex items-center justify-center svg-orange-500 relative'>{brands.tekne1}
            <div className='bg-white bg-opacity-60 p-2 rounded-full absolute bottom-0 right-0 m-5'>{icons.clock}</div>
        </div>,
        notExpandable:true,
    },
    {
        id:'tekne2',
        title:'Tekne2',
        prev:<div className='bg-slate-100 p-20 flex items-center justify-center svg-orange-500 relative'>{brands.tekne2}
            <div className='bg-white bg-opacity-60 p-2 rounded-full absolute bottom-0 right-0 m-5'>{icons.clock}</div>
        </div>,
        notExpandable:true,
    },
    {
        id:'909',
        title:'909',
        prev:<div className='bg-yellow-300 p-20 flex items-center justify-center svg-purple-700 relative'>{brands.nineonine}
            <div className='bg-white bg-opacity-60 p-2 rounded-full absolute bottom-0 right-0 m-5'>{icons.clock}</div>
        </div>,
        notExpandable:true,
    },
    {
        id:'mashup',
        title:'Mashup',
        prev:<div className='bg-red-500 p-20 flex items-center justify-center svg-white relative'>{brands.mashup}
            <div className='bg-white bg-opacity-60 p-2 rounded-full absolute bottom-0 right-0 m-5'>{icons.clock}</div>
        </div>,
        notExpandable:true,
    },
    {
        id:'ash',
        title:'Ash',
        prev:<div className='bg-violet-100 p-20 flex items-center justify-center svg-violet-700 relative'>{brands.ash}
            <div className='bg-white bg-opacity-60 p-2 rounded-full absolute bottom-0 right-0 m-5'>{icons.clock}</div>
        </div>,
        notExpandable:true,
    },
    {
        id:'roma',
        title:'Eco Roma',
        prev:<div className='bg-slate-100 p-20 flex items-center justify-center svg-green-500 relative'>{brands.roma}
            <div className='bg-white bg-opacity-60 p-2 rounded-full absolute bottom-0 right-0 m-5'>{icons.clock}</div>
        </div>,
        notExpandable:true,
    },
]

export const data = [
    {
        id:'about',
        prev:<Link to='/about' className='block text-slate-700 bg-white p-5 md:p-10 border rounded-3xl'>
            <p className='mb-3'>Hi, i'm Alessandro Cavaterra, a front-end developer with 20+ years experience and a solid designer background. Based in Rome, Italy.</p>
            <div className='text-indigo-500'>&rarr; Read More</div>
        </Link>,
        content:<>
            <div className='bg-slate-100 relative'>
                <img src='img/drama.jpg' alt='Drama' className='w-full'/>
                <div className='z-10 drop-shadow-xl bg-cover bg-center border-4 md:border-8 border-white absolute w-[120px] md:w-[200px] h-[120px] md:h-[220px] bottom-0 left-0 ml-5 md:ml-10 -mb-10 rounded-xl' style={{backgroundImage:'url("img/me.jpg")'}}></div>
            </div>
            <div className='text-right text-slate-400 text-xs p-4 mb-5'>
                Illustration by Roger Dean
            </div>
            <div className='p-5 md:p-10 pt-0 md:pt-0'>
                <p className='mb-5'>Hi, i'm Alessandro Cavaterra, a developer and designer based in Rome, Italy.</p>
                <p className='mb-5'>I'm a front-end developer with 20+ years experience and a solid designer background, I usually take care of both the design and the development process of my works. I love designing clean, crisp user experiences.</p>
                <div className='m-5 md:m-10 grid grid-cols-2 md:grid-cols-4 gap-5 svg-slate-400'>
                    <div className='icon flex justify-center'>{icons.react}</div>
                    <div className='icon flex justify-center'>{icons.next}</div>
                    <div className='icon flex justify-center'>{icons.sass}</div>
                    <div className='icon flex justify-center'>{icons.tailwind}</div>
                </div>
                <p className='mb-5'>Today i mainly work with <span className='accent'>React</span>, <span className='accent'>NextJS</span>, <span className='accent'>SASS / SCSS</span> but my love for <span className='accent'>Tailwind</span> is growing strong. I've been working with WordPress since 2006 developing <Link className='accent underline' to='/superthemes'>Themes</Link> and <Link className='accent underline' to='/css-hero'>Plugins</Link> now used by hundreds of thousands. My first experiments on web are dated back to the early 2000's when MP3s were hype at <a href='http://web.archive.org/web/20030412055725fw_/http://iberican.altervista.org/index.htm' target='_blank' className='accent underline'>ar2k.tk</a> (previously ar2k.da.ru).</p>
                <p className='mb-3'>When I'm not working or playing with my cats you can catch me (if you can) running with my black rocket <a href='https://www.instagram.com/p/CByrIFYloyp/' className='underline accent' target='_blank'>Δήμητρα</a>.</p>
            </div>
            <div className='p-5 md:p-10 bg-slate-100'>
                <h2 className='mb-3'>Contacts</h2>
                <p>Feel free to mail me <a href='mailto:ale@tipografo.org'>ale@tipografo.org</a>, call me at <a href='tel:+393336143005'>+39.333.61.43.005</a> or direct me on Instagram <a href='//instagram.com/caimanobianco' target='_blank'>@caimanobianco</a> or, as last resort, try on Facebook <a href='//fb.me/alessandrocavaterra'>Alessandro Cavaterra</a>.</p>
            </div>
        </>,
        notExpandable:true,
        customClasses:'border headings-slate-800 accents-indigo-500 text-slate-600',
    },






    {
        id:'css-hero',
        link:<a href='//csshero.org' target='_blank' className='block md:inline-block mt-5 text-right md:mt-0 rounded-3xl py-2 px-4 bg-indigo-500 text-white font-bold text-base'>Link &rarr;</a>,
        title:'CSS Hero',
        prev:<div className=' bg-indigo-400 h-[590px] flex items-center justify-center svg-white'>{brands.hero}</div>,
        content:<>
            <div className='p-5 md:p-10 pt-0 md:pt-0'>
                <p>CSS Hero is the leading standard <span className='accent'>WordPress</span> Plugin to easily <span className='accent'>customize wp themes</span> with an easy point and click interface. Developed for a smooth WYSIWYG use and a precise editing experience for those familiar with code.</p>
            </div>

            <div className='bg-slate-100 p-5 md:p-10'>
                <div className='browser bg-slate-900'>
                    <div className='h'></div>
                    <video autoPlay loop muted playsInline>
                        <source src="https://www.csshero.org/wp-content/themes/dingo22/assets/vids/home.mp4" type="video/mp4" />
                    </video>
                </div>
            </div>
            
            <div className='grid grid-cols-1 md:grid-cols-2'>
                <div className='p-5 md:p-10'>
                    <h3 className='title mb-3'>For those unfamiliar with code</h3>
                    <p>It provides a wide set of plug and play options to customise their WP Themes, from simple tasks such as changing font size and colors to more complex duties such as adding a gradient, shadows or background videos.</p>
                </div>
                <div className='p-5 md:p-10'>
                    <h3 className='title mb-3'>For WordPress Developers</h3>
                    <p>Customising WP Themes can be challenging, having a live feedback on each edit and not having to deal with FTP and template scoping is a huge time saver.</p>
                </div>
            </div>
           
            <div className='grid grid-cols-1 md:grid-cols-2 bg-slate-200 m-5 md:m-10 rounded-3xl'>
                <div className='p-5 md:p-10'>
                    <img src='img/hero2.png' className='max-h-[780px] w-auto mx-auto' />
                </div>
                <div className='p-5 md:p-10'>
                    <img src='img/hero10.png' className='max-h-[780px] w-auto mx-auto' />
                </div>
            </div>
            <div className='p-5 md:p-10 bg-yellow-500 text-yellow-700 m-5 md:m-10 md:mb-0 rounded-3xl'>
                <p className='text-lg md:text-2xl'>"If you’ve ever been one of those folks that worries that you don’t know enough CSS to make a site look like you want it to, you can stop stressing right about now"</p>
                <b className='mt-3 block'>Chris Lema - WordPress Guru</b>
            </div>
            <div className='p-5 md:p-10 max-w-[700px]'>
                <h2 className='title mb-2'>Under the hood</h2>
                <p className='mb-2'>CSS Hero's main engine is built to support and live-parse <span className='accent'>LESS</span> code.</p>
                <p className='mb-2'>Compiling LESS on the fly can be quite consuming when dealing with long, deeply nested code, hence to provide the smoothest possible experience all compiling tasks are run asynchronously by a <span className='accent'>Web Worker</span>.</p>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-6'>
                <div className='p-5 lg:p-10 relative col-span-6 md:col-span-3 bg-slate-700'>
                    <span className='opacity-50 absolute font-bold top-0 right-0 text-xs p-5'>Incoming code</span>
                    <SyntaxHighlighter style={codeStyle} language="less">
{`@size:1.8rem;
@space:.4rem;
@mainColor:#ff3300;
@mdBreak:1024px;

header{
    background:@mainColor;
    font-size:@size;
    padding:@space*2;
}

@media screen and (max-width:@mdBreak){
    header{
        font-size:@size*.75;
        padding:@space;
    }
}`}
                    </SyntaxHighlighter>
                </div>
                <div className='relative p-5 lg:p-10 bg-slate-900 col-span-6 md:col-span-3'>
                    <span className='opacity-50 absolute font-bold top-0 right-0 text-xs p-5'>JSON parsed response</span>
                    <SyntaxHighlighter style={codeStyle} language="javascript">
{`const variables = {
    '@size' : '1.8rem',
    '@space' : '.4rem',
    '@mainColor' : '#ff3300',
    '@mdBreak' : '1024px',
}

const edits = {
    'all':{
        'header':{
            'background' : '@mainColor',
            'font-size' : '@size',
            'padding' : '@space*2'
        }
    },
    '@media screen and (max-width:@mdBreak)':{
        'header':{
            'font-size' : '@size*.75',
            'padding' : '@space' 
        }
    }
}

return {variables,edits,...}

`}
                    </SyntaxHighlighter>
                </div>
                <div className='p-5 lg:p-10 relative bg-slate-800 col-span-6'>
                    <span className='opacity-50 absolute font-bold top-0 right-0 text-xs p-5'>compiled CSS</span>
                    <SyntaxHighlighter style={codeStyle} language="css">
{`header{
    background-color : #ff3300;
    font-size: 1.8rem;
    padding: .8rem;
}

@media screen and (max-width 1024px){
    header{
        font-size:1.35rem;
        padding:.4rem;
    }
}

`}
                    </SyntaxHighlighter>
            </div>
            </div>
            <div className='text-right p-10 bg-indigo-500 text-indigo-200'>
                Find out more about <a className='text-white' href='//csshero.org' target='_blank'>CSS Hero &rarr;</a>
            </div>
        </>,
        meta:['React','SCSS','Parser','LESS','WebWorkers','WordPress','Plugin'],
        customClasses:'bg-slate-50 accents-indigo-500 text-slate-600 svg-indigo-500',
        
    },
    {
        id:'animator',
        title:'Scroll Animator',
        prev:<div className='bg-slate-100 p-20 flex items-center justify-center svg-indigo-500 '>{brands.ani}</div>,
        content:<>
            <p className='p-5 pt-0 md:p-10 md:pt-0'>A tool to easily apply timed and on scroll animations such as parallax, composition and locomotive.</p>
            <div className='py-5 md:p-10 bg-slate-100'>
                <img src='img/animator01.png' alt='Animator' className='drop-shadow-xl mx-auto' />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 items-center'>
                <div className='py-5 md:p-10'>
                    <img src='img/animator02.png' alt='Animator' className='max-w-[340px] rounded-2xl mx-auto' />
                </div>
                <div className='py-5 md:p-10'>
                    <img src='img/animator03.png' alt='Animator' className='max-w-[340px] rounded-2xl mx-auto drop-shadow-xl' />
                </div>
                <div className='py-5 md:p-10'>
                    <img src='img/animator05.png' alt='Animator' className='max-w-[340px] rounded-2xl mx-auto' />
                </div>
                <div className='py-5 md:p-10'>
                    <img src='img/animator06.png' alt='Animator' className='max-w-[340px] rounded-2xl mx-auto drop-shadow-xl' />
                </div>
            </div>

            <div className='p-5 md:p-10 bg-orange-400 text-orange-800'>
                <h2 className='mb-3'>Under development</h2>
                <p>I'm currently working on this :)</p>
            </div>
        </>,
        meta:['React','SCSS','Parallax'],
        customClasses:'bg-slate-50 accents-teal-400 headings-slate-700 text-slate-500',
    },
    {
        id:'maserati',
        title:'Maserati Motorshow App',
        prev:<div className='bg-blue-100 flex p-20 h-[500px] items-center justify-center svg-sky-800'>{brands.mase}</div>,
        content:<>
        <div className='p-5 md:p-10 md:pt-0 pt-0'>
             <p className='mb-3'>The main goal of the app overhaul was to increase user engagement through immersive galleries and surveys. Data collected from surveys was sent live to the IT office on each iteration for real-time data analysis.</p><p>Those were the times when <span className='accent'>jQuery</span> was everywhere - fun times.</p>
        </div>
            <div className='p-5 md:p-10 bg-slate-100'>
                <img className='drop-shadow-xl' alt='hero' src='img/masero.png' />
            </div>
            
            <div className='p-5 md:p-10'>
                    <p>Technically speaking the App was an HTML Webapp loaded and (partially) cached on the device. All static assets were loaded and cached on the device while data was fetched from a remote JSON source which was compiled from a XLS file. <span className='accent'>I remember this at the time felt pretty much like flying</span>.</p>
                    <img className='mx-auto my-10' alt='hero' src='img/masero4.png' />
                    <p>By doing so we had the chance to let the Client have full control on displayed data and fully track App usage stats which <span className='accent'>increased engagement by 270%</span> compared to previous years.</p>
                </div>
            
            <div className='p-5 md:p-10 bg-slate-100'>
           
                <img className='drop-shadow-xl' alt='hero' src='img/masero2.png' />
                </div>
        </>,
        customClasses:'bg-slate-50 accents-blue-900 headings-blue-900 text-slate-400 svg-blue-900',
        meta:['js','jQuery','WebApp']
    },


    {
        id:'evo',
        notExpandable:true,
        title:'Evolutive',
        prev:<a target='_blank' href='//tipografo.org/evolutive' className=' p-10 transition-all flex items-center bg-teal-100 text-teal-600 hover:text-teal-700'>
        <div className='pr-10 svg-teal-300'>
        <svg x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48"><g transform="translate(0, 0)"><path d="M44.707,12.293l-9-9A1,1,0,0,0,35,3H31V13a1,1,0,0,1-1,1H11a1,1,0,0,1-1-1V3H8A5.006,5.006,0,0,0,3,8V40a5.006,5.006,0,0,0,5,5H40a5.006,5.006,0,0,0,5-5V13A1,1,0,0,0,44.707,12.293ZM38,37a1,1,0,0,1-1,1H11a1,1,0,0,1-1-1V26a1,1,0,0,1,1-1H37a1,1,0,0,1,1,1Z" fill="#444444"></path><path data-color="color-2" d="M28,3H24v7a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1Z" fill="#444444"></path></g></svg>
        </div>
        <div>
            <div className='font-extrabold text-2xl'>Evolutive 5</div>
            <div className='my-3 opacity-60'>Fancy this layout? It's available as a completely editable WordPress theme for free, go give it a spin!</div>
            <div>&rarr; Get it now</div>
            </div>
        </a>,
        content:'',
        
    },
    {
        id:'mesher',
        title:'Mesher',
        prev:<div className='bg-red-100 p-20 flex items-center justify-center svg-red-400'>{brands.mesher}</div>,
        content:<>
            <div className='p-5 md:p-10 pt-0 md:pt-0'>
            <p><a className='accent' href='//csshero.org/mesher' target='_blank'>Mesher</a> is a simple tool i've built to generate cool meshed background images with vanilla CSS.</p>
         <p>It relies on HSLA and a set of functions to provide consistent and nicely arranged vanilla CSS color meshes.</p>
         </div>
            <div className='bg-slate-500 p-5 md:p-10'>
                <div className='browser bg-slate-800'>
                    <div className='h'></div>
                    <img src='img/mesher4.png' alt='Mesher' />
                </div>
            </div>
            <div className='p-5 md:p-10'>
                <p>From a technical point of view the whole App relies on a starting color value around which a set of matching colors is generated and placed on a <span className='accent'>background-image</span> map.</p>
                        
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 items-center'>
                <div className='p-5 md:p-10 bg-slate-500'>
                    <img src='img/mesher5.png' alt='Mesher' className='rounded-xl mx-auto' />
                </div>
                <div className='p-5 md:p-10'>
                <p className='mb-4'>Generating nicely arranged colors is done by manipulating colors in <span className='accent'>HSLA</span> mode which allows full control via JS.</p>
                <p>Each color spot can be adjusted in hue, saturation, lightness and position on the canvas. The generated output is a simple one-liner CSS rule which includes each gradient step, check below:</p>
                </div>
            </div>
            <div className='p-10 bg-slate-900'>
                    <SyntaxHighlighter language="css" style={codeStyle}>
{`background-image: radial-gradient(at 40% 20%, hsla(28,100%,74%,1) 0, transparent 50%),
    radial-gradient(at 80% 0%, hsla(189,100%,56%,1) 0, transparent 50%),
    radial-gradient(at 0% 50%, hsla(355,85%,93%,1) 0, transparent 50%),
    radial-gradient(at 80% 50%, hsla(340,100%,76%,1) 0, transparent 50%),
    radial-gradient(at 0% 100%, hsla(22,100%,77%,1) 0, transparent 50%),
    radial-gradient(at 80% 100%, hsla(242,100%,70%,1) 0, transparent 50%),
    radial-gradient(at 0% 0%, hsla(343,100%,76%,1) 0, transparent 50%);
`}
      </SyntaxHighlighter>
            </div>
            <div className='h-[300px] md:h-[500px]' style={{backgroundColor:'#ff0000',backgroundImage:`radial-gradient(at 40% 20%, hsla(28,100%,74%,1) 0, transparent 50%), radial-gradient(at 80% 0%, hsla(189,100%,56%,1) 0, transparent 50%), radial-gradient(at 0% 50%, hsla(355,85%,93%,1) 0, transparent 50%), radial-gradient(at 80% 50%, hsla(340,100%,76%,1) 0, transparent 50%), radial-gradient(at 0% 100%, hsla(22,100%,77%,1) 0, transparent 50%), radial-gradient(at 80% 100%, hsla(242,100%,70%,1) 0, transparent 50%), radial-gradient(at 0% 0%, hsla(343,100%,76%,1) 0, transparent 50%)
`}}></div>
        </>,
        meta:['React','SCSS','HSLA'],
        customClasses:'bg-slate-800 accents-red-400 headings-slate-200 text-slate-400 svg-red-400',
        link:<a href='//csshero.org/mesher' target='_blank' className='block md:inline-block mt-5 text-right md:mt-0 rounded-2xl py-2 px-4 bg-red-400 text-slate-800 font-bold text-base'>Link &rarr;</a>,
    },
    {
        id:'gopadel',
        title:'GOPadel',
        prev:<div className='bg-orange-100  flex p-20 items-center justify-center svg-orange-500'>{brands.gopa}</div>,
        content:<>
            <div className='p-5 md:p-10 md:pt-0 pt-0'>
            <p>A React based fun project. The main goal of the app was to provide an easy and fun way to chart players in padel clubs similarly to ATP rankings.</p>
            </div>
            <div className='grid bg-orange-100 grid-cols-1 md:grid-cols-2 justify-center'>
                <div className='p-5 md:p-10'>
                    <img src='img/gopa2.png' alt='goPadel' />
                </div>
                <div className='p-5 md:p-10'>
                    <img src='img/gopa4.png' alt='goPadel' />
                </div>
            </div>
            <div className='p-5 md:p-10'>
            <p>An App to provide Padel Clubs with player charts with a simple user interface and a fair rating system. A <span className='accent'>React + Redux</span> frontend with a decoupled WordPress backend running with <span className='accent'>WP's Rest API</span>.</p>
            </div>
            <div className='p-5 md:p-10 border-t border-orange-100'>
                <h2 className='mb-3'>A fair rating system</h2>
                <p>The fun part of this project it's been building a fair and robust rating system. In order to be trustable a competitive rating calculator should take in considerations a high number of factors:</p>
                <ul className='bg-orange-100 my-5 rounded-xl'>
                    <li className='border-b p-3 border-orange-50'>Player Rating</li>
                    <li className='border-b p-3 border-orange-50'>Match sets offset</li>
                    <li className='border-b p-3 border-orange-50'>Match games offset</li>
                    <li className='border-b p-3 border-orange-50'>Player trend</li>
                    <li className='border-b p-3 border-orange-50'>Underdog team winning</li>
                    <li className='border-b p-3 border-orange-50'>Combined team value</li>
                    <li className='p-3'>Logical split winning/losing points among players</li>
                </ul>
                <p>Code below could be explained as it follows:</p>
                <ul className='bg-orange-100 my-5 rounded-xl'>
                    <li className='border-b p-3 border-orange-50'>Backend receives a new match in JSON format</li>
                    <li className='border-b p-3 border-orange-50'>Define winning and losing teams counting set wins</li>
                    <li className='border-b p-3 border-orange-50'>Find out the winning games margin &mdash; the more the offset value is the more points the winners will win and losers will loose.</li>
                    <li className='border-b p-3 border-orange-50'>Get each Team overall rating value &mdash; calculate differential among teams</li>
                    <li className='border-b p-3 border-orange-50'>Share winning and losing points among teams</li>
                    <li className='border-b p-3 border-orange-50'>Check whether winning team was underdog &mdash; eventually assign bonus and malus points</li>
                    <li className='p-3'>Share winning and losing points among team players based on a ranked proportion among each team's player</li>
                </ul>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2'>
                <div className='p-5 md:p-10 bg-slate-800'>
                <SyntaxHighlighter style={codeStyle} language="javascript">
{`
// PLAYERS ARRAY IN DB
const players = {
    // ...
    3450:{
        // ...
        name:'Player 1',
        ratings:[
        {
            date:1646382136783,
            rate:426.13,
            trend:1.22
        },
        {
            date:1646167823452,
            rate:432.24,
            trend:-1.26
        },
        {
            date:1646123678944,
            rate:418.15,
            trend:1.05
        }
        ]
    }
}

// MATCH DATA STRUCTURE FROM FRONTEND
const matchData = {
    date:1646383011900,
    teams:[
        [3450,5674],
        [2494,4890],
    ],
    result:[
        [6,4],
        [2,6],
        [6,1]
    ]
}
`}
            </SyntaxHighlighter>
                </div>
                <div className='p-5 md:p-10 bg-slate-700'>
                <SyntaxHighlighter style={codeStyle} language="javascript">
{`const updateRatings = match => {
    const {date,teams,result} = match;
    
    // CALC TEAM SETS
    const sets = [0,0]
    result.forEach(set=> sets[set[0] > set[1] ? 0 : 1] += 1)
    
    // DEFINE WINNER / LOSER TEAM INDEX
    const winner = sets[0] > sets[1] ? 0 : 1;
    const loser  = sets[0] > sets[1] ? 1 : 0;

    // CALC GAMES
    const games = [
        result.reduce((t,v) => t + v[0],0),
        result.reduce((t,v) => t + v[1],0)
    ]
    
    // CALC GAMES OFFSET FOR WINNERS
    const gamesOffset = games[winner] - games[loser]

    // GET TEAM RATING OUTPUT
    const winnerRatings = teams[winner].reduce((t,id) => t + players[id].ratings[0].rate,0)
    const loserRatings = teams[loser].reduce((t,id) => t + players[id].ratings[0].rate,0)
    
    // TEAMS DIFFERENTIAL
    const teamDiff = winnerRatings - loserRatings;

    // CHECK IF WINNER TEAM WAS UNDERDOG (mixed lower rating than losers)
    const underDogBonus = teamDiff < 0 ? Math.abs(teamDiff)/5 : 1;
    
    // CALC WINNING POINTS
    const assignablePoints = teamDiff / winnerRatings;
    
    // SPLIT POINTS BY WINNING PLAYERS
    teams[winner].forEach(id=>{
        const {ratings} = players[id]
        const {rate , trend} = ratings[0]
        const newRate = rate * (assignablePoints * underDogBonus * trend)
        players[id] = {...players[id],ratings:{...ratings,{date,rate:newRate,trend:newTrend}}}
    })
    // ... ON WITH SOME MATH
}`}
            </SyntaxHighlighter>
                </div>
            </div>
            <div className='p-5 md:p-10 bg-orange-100'>
                <img className='mx-auto' src='img/gopa6.png' alt='GOPadel' />
            </div>
            <div className='p-5 md:p-10 bg-[#f96c26]'>
                <img className='mx-auto' src='img/gopa7.png' alt='GOPadel' />
            </div>
        </>,

        meta:['React','Redux','headless','WordPress','SCSS'],
        customClasses:'bg-orange-50 text-slate-600 headings-slate-700 accents-indigo-500 svg-indigo-500'
    },
    
    {
        id:'carito-and-partners',
        title:'Carito & Partners',
        prev:<div className='bg-yellow-100 flex p-20 items-center justify-center svg-yellow-300'>{brands.carito}</div>,
        content:<>
            <div className='p-5 md:p-10 md:pt-0 pt-0'>
                <p>In 2010 I joined Carito & Partners as Art Director and my first task has been redesigning the group identity.</p>
                <p>I came up with a monogram including the initial letters of the group C / P joined by the & symbol.</p>
            </div>
            
            <div className='bg-yellow-300 grid grid-cols-1 md:grid-cols-2 items-center'>
                <div className='p-5 md:p-10'>
                    <p>When it comes to brand identity my goal is to design solid, crisp and easily recognizable marks. </p>
                </div>
                <div className='p-5 md:p-10'>
                    <img src='img/carito8.png' alt='Carito Branding' />
                </div>
            </div>
            <div className='p-5 md:p-10 flex justify-center bg-slate-900 svg-white'>
                {brands.carito}
            </div>
            <div className='bg-yellow-400 grid grid-cols-1 md:grid-cols-2 p-5 md:p-10 gap-5 md:gap-10'>
                <img src='img/carito10.png' alt='Carito Branding' />
                <img src='img/carito11.png' alt='Carito Branding' />
            </div>
            <div className='p-5 pb-0 md:p-10 md:pb-0'>
                <h3 className='title'>Some stationery</h3>
            </div>   
            <div className='grid grid-cols-1 md:grid-cols-2 items-center p-5 md:p-10 gap-5 md:gap-10'>
                <img className='mx-auto' src='img/carito5.png' alt='Carito Branding' />
                <img className='mx-auto' src='img/carito3.png' alt='Carito Branding' />
                <img className='mx-auto' src='img/carito4.png' alt='Carito Branding' />
                <img className='mx-auto' src='img/carito6.png' alt='Carito Branding' />
           </div>
        </>,
        customClasses:'bg-yellow-100 accents-slate-900 headings-slate-900 text-slate-700',
        meta:['Branding']
    },
    {
        id:'globesoccer',
        title:'Globesoccer',
        prev:<div className='bg-emerald-100  flex p-20 items-center justify-center svg-emerald-400'>{brands.globesoccer}</div>,
        content:<>
            <div className='p-5 md:p-10 md:pt-0 pt-0'>Logo for Dubai's prestigious international football awards event.</div>
            <div className='bg-slate-900 p-5 md:p-10'>
                 <img className='mx-auto max-w-[300px]' src='img/globesoccer1.png' alt='Globesoccer logo' />
            </div>
            <div className='p-5 md:p-10'>
                <p className='mb-5'>The Globe Soccer Awards are football awards organised by the European Club Association (ECA) in conjunction with the European Association of Player's Agents (EFAA) and delivered by the organisation Globe Soccer.</p>
                <p>The award ceremony is held in the city of Dubai in the United Arab Emirates, which brings together the main representatives of the world of football (FIFA, ECA, UEFA), the League of Arab Emirates, major world clubs and their managers, encouraging an exchange of views on the world of football as a major goal, alongside transfer-related issues and the football market. <a target='_blank' href='//en.wikipedia.org/wiki/Globe_Soccer_Awards' className='accent underline'>Wiki</a>.</p>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 p-5 gap-y-5 md:gap-5'>
                <img src='img/gs2.jpeg' alt='Globesoccer' className='col-span-2' />
                <img src='img/gs1.jpeg' alt='Globesoccer' />
                
                <img src='img/gs5.jpeg' alt='Globesoccer' />
                <img src='img/gs4.jpeg' alt='Globesoccer' />
                <img src='img/gs3.jpeg' alt='Globesoccer' />
            </div>
            <div className='bg-emerald-500 p-20'>
            <img className='mx-auto max-w-[200px]' src='img/globesoccer2.png' alt='Globesoccer logo' />
            </div>
        </>,
        customClasses:'svg-emerald-500 bg-slate-800 accents-emerald-500 headings-slate-200 text-slate-300',
        meta:['Branding'],
        link:<a href='//globesoccer.com' target='_blank' className='block md:inline-block mt-5 text-right md:mt-0 rounded-2xl py-2 px-4 bg-emerald-500 text-white font-bold text-base'>Link &rarr;</a>,
    },
    {
        id:'superthemes',
        title:'Superthem.es',
        prev:<div className='bg-lime-200  flex p-20 items-center justify-center svg-teal-700'>{brands.supert}</div>,
        content:<>
            <div className='p-5 md:p-10 md:pt-0 pt-0'>Superthem.es was a collection of super-powered WordPress portfolio themes featuring a high level of customizations with <span className='accent'>LTE (live theme editor)</span> which then got abstracted and became <Link className='accent underline' to='/css-hero'>CSS Hero</Link>.</div>
            <div className='p-5 md:p-10 bg-slate-700'>
                <div className='browser bg-slate-900'>
                    <div className='h'></div>
                    <img src='img/e41.jpeg' alt='Superthemes' />
                </div>
            </div>
            <div className='p-5 md:p-10'>
                <p>As you can see I've always been pretty fascinated by David Desandro's <a href='//masonry.desandro.com/' className='accent underline' target='_blank'>Masonry</a>. </p>
            </div>
            <div className='p-5 md:p-10 bg-slate-700'>
                <div className='browser bg-slate-900'>
                    <div className='h'></div>
                    <img src='img/e4.jpeg' alt='Superthemes' />
                </div>
            </div>
            <div className='p-5 md:p-10'>
                <p>Top selling theme was Evolutive, a fully responsive (yes this was a thing back in the days) fluid grid based theme which allowed users to customise it's look and feel with a simple <span className='accent'>WYSISWYG</span> editing panel (and yes this was a thing too :D)</p>
            </div>
            <div className='p-5 md:p-20 bg-lime-300 text-emerald-700'>
                <p className='text-xl'>"Superthemes is a fantastic product. It makes it easy for people to express themselves"</p>
                <span className=' text-base font-bold'>David DeSandro (Designer at Twitter)</span>
            </div>
            <div className='p-5 md:p-10 bg-slate-700'>
            <div className='browser bg-slate-900'>
                    <div className='h'></div>
                    <img src='img/st.jpg' alt='Superthemes' />
                </div>
            </div>
            <div className='p-5 md:p-20 bg-lime-300 text-emerald-700'>
                <p className='text-xl'>"I couldn't really be creative with other themes: I was about to give up searching, then i've found Superthemes."</p>
                <span className=' text-base font-bold'>Jennifer Cirpici (Designer, Founder of OtherFocus)</span>
            </div>
            
        </>,
        customClasses:'svg-lime-200 bg-slate-800 accents-lime-200 headings-slate-200 text-slate-300',
        meta:['js','css','WordPress','Themes']
    },
    {
        id:'romides',
        title:'Romides',
        content:<>
        <div className='px-5 md:px-10'>
            <p>Romides was supposed to be a social-oriented football match generator but unfortunately it never saw the light. I’ve been asked to design it’s identity and here’s what i came up with</p>
        </div>
        <div className='p-5 md:p-10'>
            <img src='img/romi-3.jpg' alt='Romides' className='rounded-3xl' />
        </div>
        <div className='p-5 md:p-10 bg-slate-200'>
            <img src='img/romi-1.jpg' alt='Romides' className='rounded-3xl drop-shadow-xl' />
        </div>
        
        <div className='p-5 md:p-10'>
            <p>The main concept borrows the typical maps marker icon to create an R letter as the whole app was supposed to rely on geolocalization to create and join football matches around the user.</p>
        </div>
        <div className='p-5 md:p-10 bg-slate-700'>
            <img src='img/romi-4.jpg' alt='Romides' className='rounded-md drop-shadow-xl' />
        </div>
        <div className='p-5 md:p-10'>
            <img src='img/romi.jpeg' alt='Romides' className='rounded-md mx-auto drop-shadow-xl' />
        </div>
        </>,
        prev:<div className='bg-teal-100  flex p-20 items-center justify-center svg-teal-700'>{brands.romides}</div>,
        meta:['Branding'],
        customClasses:'svg-teal-600 bg-slate-800 accents-teal-200 headings-slate-200 text-slate-300',
    }
]
import './scss/output.css';
import {BrowserRouter as Router,Link,Route,Switch} from "react-router-dom";
import Nav from './Nav'
import Home from './Home'
import {useState} from 'react'
import { icons } from './icons';
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-1903278-1";
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);
const App = () => {
  const [loading,setLoading] = useState(false)
  return <Router>
      <div className='icons-dark header pl-10 pt-5 flex justify-between items-center'>
        <Link className='flex text-slate-700 items-center gap-2' to='/'>
          <h1 className='font-extrabold text-xl'>Tipografo</h1>
          {loading ? <div className='animate-spin svg-slate-600'>{icons.loader}</div> : ''}
        </Link>
        
        <Nav />
      </div>
      <Home loading={loading} setLoading={setLoading} />
      <div className='p-0 md:p-10 pb-0 text-sm bg-gradient-to-b mt-40 from-white to-sky-100'>
      <img src='img/tipo.png' alt='Tipografo' className='mx-auto' />
      <div className='text-slate-400 border-t border-sky-100 p-10 grid gap-5 grid-cols-1'>
        <div>
          <span className='text-slate-600'>&copy; Alessandro Cavaterra 2022</span><br />&mdash; Built with React and Tailwind.</div>
      
        <div className='max-w-[500px]'>
        All trademarks, logos and brand names are the property of their respective owners. All company, product and service names used in this website are for identification purposes only.
        </div>
    </div>
    </div>
    
  </Router>
}
export default App;

import {data,gridClasses,oldies} from './data/data'
import Masonry from 'react-masonry-component';
import { useState, useRef } from 'react';
import Card from './Card'

const Home = ({loading,setLoading}) => {
    const [firstLoad,setFirstLoad] = useState('opacity-0')
  
    const masonryOptions = {
      transitionDuration: 300,
      fitWidth: true,
      stagger: 50,
      columnWidth: '.grid-sizer',
      itemSelector: '.grid-item',
      percentPosition: true,
    };

    const all = [...data,...oldies]
    const childElements = all.map((element,idx)=><Card loading={loading} setLoading={setLoading} key={idx} element={element} />)
  
   
    const gridRef = useRef(null)
    return <Masonry
        ref={gridRef}
        className={'masonry transition-opacity duration-500 '+firstLoad}
        options={masonryOptions}
        onImagesLoaded={()=>gridRef.current.performLayout()}
        onLayoutComplete={()=>{
          setLoading(false);
          if (firstLoad === 'opacity-0') setFirstLoad('opacity-100')
        
          setTimeout(()=>{
            const dest = document.querySelector('.grid-open:not(.scrolled')
            if (dest){
              const top = parseInt(window.getComputedStyle(dest).top)
              window.scrollTo({top,left: 0,behavior:'smooth'});
              dest.classList.add('scrolled')
            }
        },600)
        }}>
        <div className={'grid-sizer '+gridClasses.normal}></div>
        {childElements}
      </Masonry>
  } 

  export default Home;
import { useLocation, Link } from "react-router-dom"

import {gridClasses} from './data/data'
import Meta from "./Meta";
import { useEffect , useState} from 'react'
import { icons } from "./icons";
const Card = props => {
    const {element,loading,setLoading} = props;
    const {title,id,doubleWidth,content,prev,notExpandable,meta,customClasses,link} = element;
    const location = useLocation()
    const {pathname} = location;
    const [loadingClass,setLoadingClass] = useState('opacity-0')


    const [cnt,setCnt] = useState('')
    let cl = customClasses && pathname && pathname.indexOf('/'+id) > -1 ? customClasses : 'bg-white' 
    useEffect(()=>setLoading(pathname),[pathname])
    useEffect(()=>{
        setLoadingClass(loading && loading.indexOf('/'+id) > -1 ? 'opacity-0' : 'opacity-100')
        setCnt(contents())
    },[loading])

    const lnk = link ? link : []
    
    const widthClass = pathname.indexOf('/'+id) > -1 ? 'grid-open '+gridClasses.expanded : doubleWidth ? gridClasses.expanded : gridClasses.normal 
   
  
    
    const contents = () => {
        

        const link = notExpandable ? [] : <><Link onMouseDown={()=>setLoading(id)} to={id} className={'absolute inset-0 w-full h-full z-20'}></Link>
        <div className='relative md:absolute bg-white md:bg-slate-800 inset-0 flex items-end p-5 md:p-10 pb-0 md:pb-10 w-full h-full transition-opacity opacity-100 md:opacity-0 md:group-hover:opacity-80 text-slate-800 md:text-white z-10'>
            <div>
                <h2 className='font-extrabold text-lg'>{title}</h2>
                <div className='md:svg-white'>
                    <Meta meta={meta} />
                </div>
            </div>
        </div>
        </>

        const titleSection = title ? <div className='p-5 pb-0'>
            <div className='flex items-center justify-between'>
                <h1 className='pl-0 md:p-5 pb-0 md:pb-0 title'>{title}</h1>
                <Link className='p-5 py-0 md:p-5 close' to='/'>{icons.close}</Link>
            </div>
            <div className='block md:flex gap-3 items-center justify-between py-5 md:p-5'>
                
                <Meta meta={meta} />
                {lnk}
            </div>
        </div> : <Link className='absolute top-0 right-0 p-5 md:p-10 z-20 close' to='/'>{icons.close}</Link>
        
        if (pathname.indexOf('/'+id) > -1){
            return <>
                {titleSection}
                {content ? content : <div className='p-10'>Emptyness...</div>}
            </>
        } else {
            return <div className='prev group'>
                
                {prev}
                {link}
            </div>
        }
    }
    

    
    

    return <div className={widthClass+' grid-item p-5'}>
        <div className={loadingClass+' overflow-hidden rounded-3xl relative z-10 '+cl}>
           {cnt}
        </div>
    </div>

}

export default Card;
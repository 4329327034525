export const icons = {
    mail:<svg x="0px" y="0px" width="12px" height="12px" viewBox="0 0 12 12"><g transform="translate(0, 0)"><path d="M11.993,5.35a5.34,5.34,0,0,1-.338,1.934A3.205,3.205,0,0,1,10.7,8.661a2.212,2.212,0,0,1-1.431.5A1.781,1.781,0,0,1,8.308,8.9a1.5,1.5,0,0,1-.6-.686H7.589a2.517,2.517,0,0,1-2.018.947,2.7,2.7,0,0,1-2.048-.782,2.964,2.964,0,0,1-.749-2.139,3.3,3.3,0,0,1,.984-2.5A3.549,3.549,0,0,1,6.327,2.8a8.974,8.974,0,0,1,1.39.121,7.738,7.738,0,0,1,1.252.29L8.8,6.8q0,1.02.558,1.021.469,0,.748-.687a4.83,4.83,0,0,0,.279-1.794,4.317,4.317,0,0,0-.491-2.088A3.333,3.333,0,0,0,8.51,1.864a4.249,4.249,0,0,0-2.037-.477A5.212,5.212,0,0,0,3.9,2,4.071,4.071,0,0,0,2.22,3.754a5.78,5.78,0,0,0-.583,2.653A4.211,4.211,0,0,0,2.719,9.516,4.253,4.253,0,0,0,5.857,10.6a9.444,9.444,0,0,0,1.71-.172,11.373,11.373,0,0,0,1.835-.5v1.409A8.825,8.825,0,0,1,5.916,12a6.094,6.094,0,0,1-4.349-1.468A5.331,5.331,0,0,1,.007,6.451,6.829,6.829,0,0,1,.8,3.16,5.786,5.786,0,0,1,3.072.84,6.711,6.711,0,0,1,6.459,0,6.148,6.148,0,0,1,9.343.661,4.68,4.68,0,0,1,11.3,2.539,5.609,5.609,0,0,1,11.993,5.35Zm-7.516.918q0,1.548,1.262,1.549A1.173,1.173,0,0,0,6.745,7.35a2.942,2.942,0,0,0,.418-1.515l.1-1.622a4.01,4.01,0,0,0-.844-.081,1.812,1.812,0,0,0-1.428.573A2.263,2.263,0,0,0,4.477,6.268Z" ></path></g></svg>,
    about:<svg x="0px" y="0px" width="12px" height="12px" viewBox="0 0 12 12"><g transform="translate(0, 0)"><circle cx="6" cy="3" r="3"  data-color="color-2"></circle><path d="M6,7C2.625,7,1,9.106,1,10.333V11a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1v-.667C11,9.106,9.375,7,6,7Z" ></path></g></svg>,
    tag:<svg x="0px" y="0px" width="12px" height="12px" viewBox="0 0 12 12"><g transform="translate(0, 0)"><path d="M6.414,12A1.993,1.993,0,0,1,5,11.414L.293,6.707A1,1,0,0,1,0,6V1A1,1,0,0,1,1,0H6a1,1,0,0,1,.707.293L11.414,5a2,2,0,0,1,0,2.829L7.828,11.414A1.993,1.993,0,0,1,6.414,12ZM2,5.586,6.414,10,10,6.414,5.586,2H2Z" ></path> <circle cx="4" cy="4" r="1"  data-color="color-2"></circle></g></svg>,
hash:<svg x="0px" y="0px" width="12px" height="12px" viewBox="0 0 12 12"><g transform="translate(0, 0)"><path d="M11,3H9.62l.36-1.8A1,1,0,0,0,8.02.8L7.58,3H5.62l.36-1.8A1,1,0,1,0,4.02.8L3.58,3H2A1,1,0,0,0,2,5H3.18l-.4,2H1A1,1,0,0,0,1,9H2.38l-.36,1.8A1,1,0,0,0,2.8,11.98.948.948,0,0,0,3,12a1,1,0,0,0,.979-.8L4.42,9H6.38l-.36,1.8A1,1,0,0,0,6.8,11.98.948.948,0,0,0,7,12a1,1,0,0,0,.979-.8L8.42,9H10a1,1,0,0,0,0-2H8.82l.4-2H11a1,1,0,0,0,0-2ZM6.78,7H4.82l.4-2H7.18Z" ></path></g></svg>,
link:<svg x="0px" y="0px" width="12px" height="12px" viewBox="0 0 12 12"><g transform="translate(0, 0)"><path d="M6.245.965,4.727,2.446A.75.75,0,0,0,5.773,3.521L7.3,2.033A1.875,1.875,0,1,1,9.944,4.691L8.463,6.21A.75.75,0,1,0,9.537,7.257l1.475-1.512a3.381,3.381,0,0,0,0-4.773A3.462,3.462,0,0,0,6.245.965Z" ></path> <path d="M6.227,8.446,4.7,9.935A1.875,1.875,0,1,1,2.056,7.276L3.537,5.757A.75.75,0,0,0,2.463,4.71L.988,6.222A3.375,3.375,0,0,0,5.755,11L7.273,9.521A.75.75,0,0,0,6.227,8.446Z" ></path> <path d="M3.47,8.514a.748.748,0,0,0,1.06,0l4-4A.75.75,0,1,0,7.47,3.453l-4,4A.75.75,0,0,0,3.47,8.514Z"  data-color="color-2"></path></g></svg>,
arrowRight:<svg x="0px" y="0px" width="12px" height="12px" viewBox="0 0 12 12"><g transform="translate(0, 0)"><path d="M1,7H8.586L6.293,9.293a1,1,0,1,0,1.414,1.414l4-4a1,1,0,0,0,0-1.416l-4-4A1,1,0,0,0,6.293,2.707L8.586,5H1A1,1,0,0,0,1,7Z" ></path></g></svg>,
loader:<svg x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16"><g transform="translate(0, 0)"><path d="M8,16a8,8,0,1,1,8-8A8.009,8.009,0,0,1,8,16ZM8,2a6,6,0,1,0,6,6A6.006,6.006,0,0,0,8,2Z"  opacity="0.4"></path> <path d="M16,8H14A6.006,6.006,0,0,0,8,2V0A8.009,8.009,0,0,1,16,8Z"  data-color="color-2"></path></g></svg>,
close:<svg x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16"><g transform="translate(0, 0)"><path  d="M14.7,1.3c-0.4-0.4-1-0.4-1.4,0L8,6.6L2.7,1.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L6.6,8l-5.3,5.3 c-0.4,0.4-0.4,1,0,1.4C1.5,14.9,1.7,15,2,15s0.5-0.1,0.7-0.3L8,9.4l5.3,5.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L9.4,8l5.3-5.3C15.1,2.3,15.1,1.7,14.7,1.3z"></path></g></svg>,
clock:<svg x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16"><g transform="translate(0, 0)"><path d="M8,0a8,8,0,1,0,8,8A8.024,8.024,0,0,0,8,0ZM8,14a6,6,0,1,1,6-6A6.018,6.018,0,0,1,8,14Z" ></path><path data-color="color-2" d="M11.5,7H9V4.5a1,1,0,0,0-2,0V8A1,1,0,0,0,8,9h3.5a1,1,0,0,0,0-2Z" ></path></g></svg>,
html:<svg x="0px" y="0px" width="80px" height="80px" viewBox="0 0 80 80"><g>
<path d="M4.73,0l6.419,71.999L39.958,80l28.88-8.008L75.27,0H4.73L4.73,0L4.73,0z M50.928,41.419l-1.022,11.452l-9.863,2.663
    l-9.831-2.65l-0.625-7.051h-8.867l1.237,13.867l18.132,5.013l18.073-5.013l2.422-27.109H28.402l-0.807-9.043h33.789l0.788-8.828
    H17.939l2.383,26.706L50.928,41.419L50.928,41.419z M63.337,67.409L40.12,73.841v0.033l-0.059-0.02l-0.059,0.02v-0.033
    l-23.216-6.432L11.292,5.892h57.539L63.337,67.409L63.337,67.409z"/>
</g>
</svg>,
css:<svg x="0px" y="0px" width="80px" height="80px" viewBox="0 0 80 80"><g>
<polygon points="11.908,3.799 9.219,17.23 63.893,17.23 62.181,25.908 7.48,25.902 4.831,39.333 59.499,39.333 56.445,54.652 
    34.414,61.95 15.319,54.652 16.628,48.005 3.197,48.005 0,64.124 31.582,76.208 67.988,64.124 80,3.792 	"/>
</g>
</svg>,
less:<svg x="0px" y="0px" width="80px" height="80px" viewBox="0 0 80 80"><g>
<g>
    <g>
        <path  d="M63.561,39.538c3.008,1.048,4.505,2.852,4.505,5.404c0,1.68-0.625,3.008-1.868,3.997
            c-1.243,0.983-3.014,1.595-5.319,1.595c-2.305,0-4.44-0.671-6.406-1.764c0.02-0.579,0.176-1.217,0.456-1.914
            c0.28-0.69,0.618-1.243,0.996-1.647c1.803,0.938,3.379,1.406,4.707,1.406c0.632,0,1.12-0.104,1.458-0.319
            c0.339-0.215,0.508-0.495,0.508-0.853c0-0.671-0.521-1.178-1.562-1.51l-1.868-0.703c-2.826-1.029-4.232-2.708-4.232-5.033
            c0-1.699,0.592-3.047,1.777-4.043c1.185-0.996,2.786-1.855,4.792-1.855c0.996,0,2.09,0.137,3.288,0.417
            c1.198,0.28,2.181,0.97,2.949,1.348c0.046,0.625-0.078,1.315-0.371,2.064c-0.293,0.749-0.658,1.283-1.087,1.595
            c-1.901-0.853-3.477-1.276-4.746-1.276c-0.456,0-0.794,0.098-1.035,0.306c-0.234,0.202-0.352,0.456-0.352,0.775
            c0,0.534,0.43,0.964,1.289,1.276L63.561,39.538L63.561,39.538L63.561,39.538z"/>
        <path  d="M48.555,39.538c3.008,1.048,4.505,2.852,4.505,5.404c0,1.68-0.625,3.008-1.862,3.997
            c-1.243,0.983-3.014,1.595-5.319,1.595c-2.305,0-4.44-0.671-6.406-1.764c0.02-0.579,0.176-1.217,0.456-1.914
            c0.28-0.69,0.612-1.243,0.996-1.647c1.81,0.938,3.379,1.406,4.714,1.406c0.632,0,1.12-0.104,1.458-0.319s0.508-0.495,0.508-0.853
            c0-0.671-0.521-1.178-1.562-1.51l-1.862-0.703c-2.826-1.029-4.238-2.708-4.238-5.033c0-1.699,0.592-3.047,1.777-4.043
            c1.185-0.996,2.78-1.855,4.792-1.855c0.996,0,2.09,0.137,3.288,0.417c1.198,0.28,2.181,0.97,2.949,1.348
            c0.046,0.625-0.078,1.315-0.371,2.064s-0.658,1.283-1.087,1.595c-1.901-0.853-3.477-1.276-4.746-1.276
            c-0.456,0-0.794,0.098-1.035,0.306c-0.234,0.202-0.352,0.456-0.352,0.775c0,0.534,0.43,0.964,1.289,1.276L48.555,39.538
            L48.555,39.538L48.555,39.538z"/>
        <path  d="M77.363,42.24c-0.957,0.833-1.51,2.038-1.51,3.613v5.859c0,1.947-0.599,3.431-1.953,4.447
            c-1.354,1.016-2.93,1.523-4.727,1.569l-1.068-0.007v-2.923c0.938-0.273,1.328-0.592,1.699-0.957
            c0.677-0.612,1.198-1.608,1.198-2.995v-4.733c0.026-1.608,0.15-2.871,0.736-3.776c0.586-0.911,1.732-1.706,3.431-2.396
            c-1.875-0.859-3.138-1.979-3.783-3.366c-0.378-0.82-0.384-1.934-0.384-3.327v-4.342c0-1.432-0.469-2.435-1.048-3.008
            c-0.378-0.371-0.814-0.671-1.849-0.898v-2.734c1.27,0.013,1.914,0,2.65,0c1.139-0.007,2.096,0.612,2.923,1.263
            c0.905,0.71,1.549,1.673,1.927,2.884c0.215,0.697,0.247,1.367,0.247,2.005v5.02c0,1.771,0.521,3.092,1.419,3.952
            c0.501,0.501,1.217,0.957,2.441,1.367v2.266C78.626,41.315,77.943,41.725,77.363,42.24L77.363,42.24L77.363,42.24z"/>
        <path  d="M37.604,39.772c0-1.029-0.163-1.979-0.488-2.852c-0.326-0.872-0.794-1.621-1.406-2.246
            c-0.612-0.625-1.348-1.12-2.22-1.478c-0.872-0.358-1.849-0.898-2.93-0.898c-1.289,0-2.409,0.586-3.372,1.029
            c-0.957,0.449-1.751,1.061-2.37,1.842c-0.625,0.781-1.087,1.706-1.393,2.767c-0.306,1.061-0.456,2.22-0.456,3.47
            c0,1.296,0.124,2.493,0.371,3.594c0.247,1.094,0.671,2.044,1.27,2.852c0.599,0.807,1.38,1.432,2.337,1.875
            c0.957,0.449,2.155,0.788,3.574,0.788c2.617,0,4.831-0.762,6.641-2.031c-0.176-1.296-0.638-2.324-1.38-3.086
            c-0.924,0.345-1.738,0.592-2.448,0.749c-0.71,0.15-1.335,0.228-1.875,0.228c-1.081,0-1.855-0.273-2.331-0.827
            c-0.469-0.553-0.729-1.204-0.775-2.201h8.809C37.461,42.409,37.604,41.139,37.604,39.772L37.604,39.772L37.604,39.772z
             M28.327,40.007c0.065-1.139,0.273-2.363,0.625-2.865c0.345-0.501,0.879-0.755,1.595-0.755c0.742,0,1.296,0.267,1.667,0.807
            c0.371,0.534,0.553,1.621,0.553,2.448v0.371h-4.44V40.007L28.327,40.007z"/>
        <path  d="M9.974,22.266L9.974,22.266c-0.365,0-0.697,0-0.957,0c-1.139-0.013-2.096,0.612-2.923,1.263
            c-0.905,0.71-1.68,1.641-1.921,2.884c-0.137,0.729-0.111,1.367-0.111,2.005v5.02c0,1.771-0.658,3.086-1.556,3.952
            c-0.501,0.501-1.582,0.957-2.799,1.367v2.266c1.081,0.299,2.129,0.703,2.708,1.217c0.957,0.84,1.647,2.044,1.647,3.613v5.859
            c0,1.947,0.462,3.431,1.816,4.447s2.93,1.523,4.727,1.569l1.204-0.007v-2.923c-0.938-0.273-1.458-0.592-1.836-0.957
            c-0.677-0.612-1.061-1.608-1.061-2.988V46.12c-0.026-1.608-0.286-2.871-0.872-3.783s-1.732-1.706-3.431-2.396
            c1.882-0.859,3.138-1.979,3.783-3.366c0.378-0.82,0.521-1.934,0.521-3.327v-4.342c0-1.432,0.332-2.435,0.911-3.008
            c0.313-0.312,0.866-0.566,1.641-0.775l1.224,0.007c0-1.055,0-1.712,0-2.871L9.974,22.266L9.974,22.266L9.974,22.266L9.974,22.266
            z"/>
        <path  d="M21.243,46.048h-0.918c-0.996,0-1.367-0.521-1.367-1.576v-19.31c0-1.159-0.339-1.979-0.762-2.448
            c-0.43-0.469-1.172-0.462-2.233-0.462H13.9l-0.254-0.007v0.02v2.871v20.267c0,1.615,0.365,2.793,1.009,3.542
            s1.732,1.126,3.268,1.126c1.061,0,2.272-0.111,3.626-0.339c0.065-0.228,0.104-0.71,0.104-1.452
            C21.647,47.533,21.51,46.79,21.243,46.048L21.243,46.048L21.243,46.048z"/>
    </g>
</g>
</g>
</svg>,
next:<svg x="0px" y="0px" width="80px" height="80px" viewBox="0 0 80 80"><path d="M18.877,28.43h15.091v1.202H20.261v9.046h12.89v1.202h-12.89v9.932h13.864v1.203H18.877V28.43z M35.32,28.43h1.603
l7.106,9.932l7.264-9.932l9.875-12.616L44.939,39.406l8.369,11.609h-1.674l-7.606-10.568l-7.639,10.565h-1.634l8.424-11.609
L35.32,28.43z M53.899,29.632V28.43h17.196v1.202h-7.922v21.382h-1.383V29.632h-7.895H53.899z M0,28.43h1.737L25.58,64.186
l-9.853-13.171L1.446,30.137L1.383,51.015H0V28.43z M70.955,49.447c-0.278,0.005-0.508-0.216-0.513-0.494s0.216-0.508,0.494-0.513
c0.278-0.005,0.508,0.216,0.513,0.494c0,0.004,0,0.007,0,0.011c0.007,0.27-0.206,0.495-0.477,0.502
C70.967,49.447,70.961,49.447,70.955,49.447z M72.315,48.124h0.741c0,0.373,0.303,0.675,0.677,0.675
c0.019,0,0.037-0.001,0.056-0.002c0.482,0,0.755-0.291,0.755-0.834v-3.442h0.753v3.447c0,0.979-0.565,1.543-1.5,1.543
c-0.878,0-1.481-0.546-1.481-1.385V48.124z M76.281,48.081h0.747c0.065,0.461,0.515,0.755,1.164,0.755
c0.606,0,1.05-0.316,1.05-0.745c0-0.371-0.283-0.594-0.925-0.745l-0.632-0.158c-0.878-0.207-1.279-0.632-1.279-1.348
c0-0.867,0.706-1.445,1.765-1.445c0.987,0,1.707,0.578,1.751,1.397h-0.734c-0.071-0.448-0.461-0.73-1.026-0.73
c-0.595,0-0.992,0.287-0.992,0.726c0,0.347,0.256,0.548,0.887,0.696l0.535,0.131C79.589,46.848,80,47.248,80,47.987
c0,0.932-0.722,1.516-1.871,1.516c-1.077,0-1.8-0.557-1.854-1.429L76.281,48.081z"/>
</svg>,
react:<svg x="0px" y="0px" width="80px" height="80px" viewBox="0 0 80 80"><g>
<path d="M80,40.01c0-5.299-6.634-10.319-16.81-13.438c2.35-10.371,1.302-18.62-3.294-21.263
    c-1.061-0.618-2.298-0.911-3.652-0.911v3.639c0.749,0,1.354,0.15,1.862,0.423c2.22,1.27,3.177,6.113,2.428,12.344
    c-0.182,1.53-0.475,3.145-0.833,4.792c-3.197-0.781-6.686-1.387-10.352-1.777c-2.201-3.014-4.486-5.755-6.784-8.151
    c5.312-4.948,10.299-7.65,13.691-7.65V4.378l0,0c-4.486,0-10.352,3.197-16.289,8.743C34.03,7.607,28.164,4.443,23.678,4.443v3.639
    c3.372,0,8.379,2.689,13.698,7.598c-2.285,2.396-4.564,5.117-6.732,8.138c-3.685,0.391-7.174,0.996-10.371,1.79
    c-0.378-1.628-0.651-3.21-0.846-4.727c-0.768-6.23,0.182-11.074,2.383-12.363c0.488-0.293,1.126-0.423,1.875-0.423V4.463l0,0
    c-1.367,0-2.611,0.293-3.685,0.911c-4.583,2.643-5.612,10.879-3.242,21.217C6.602,29.717,0,34.723,0,40.01
    c0,5.299,6.634,10.319,16.81,13.437c-2.35,10.371-1.302,18.62,3.294,21.263c1.061,0.618,2.298,0.911,3.672,0.911
    c4.486,0,10.352-3.197,16.289-8.743c5.938,5.514,11.803,8.678,16.289,8.678c1.367,0,2.611-0.293,3.685-0.911
    c4.583-2.643,5.612-10.879,3.242-21.217C73.398,50.316,80,45.29,80,40.01L80,40.01z M47.435,40.01c0-4.115-3.333-7.454-7.454-7.454
    c-4.115,0-7.454,3.333-7.454,7.454s3.333,7.454,7.454,7.454C44.102,47.458,47.435,44.124,47.435,40.01L47.435,40.01z M23.659,4.443
    L23.659,4.443L23.659,4.443L23.659,4.443z M58.77,29.131c-0.605,2.103-1.354,4.271-2.201,6.439
    c-0.671-1.302-1.367-2.611-2.135-3.913c-0.749-1.302-1.549-2.578-2.35-3.815C54.401,28.187,56.634,28.61,58.77,29.131L58.77,29.131
    z M51.302,46.501c-1.27,2.201-2.578,4.29-3.932,6.23c-2.428,0.215-4.889,0.326-7.37,0.326c-2.461,0-4.922-0.117-7.337-0.313
    c-1.354-1.94-2.676-4.01-3.945-6.198c-1.237-2.135-2.363-4.303-3.392-6.491c1.009-2.188,2.155-4.368,3.372-6.504
    c1.27-2.201,2.578-4.29,3.932-6.23c2.428-0.215,4.889-0.326,7.37-0.326c2.461,0,4.922,0.117,7.337,0.312
    c1.354,1.94,2.676,4.01,3.945,6.198c1.237,2.135,2.363,4.303,3.392,6.491C53.646,42.178,52.526,44.359,51.302,46.501L51.302,46.501
    z M56.569,44.378c0.879,2.188,1.628,4.368,2.253,6.491c-2.135,0.521-4.388,0.964-6.719,1.302c0.801-1.257,1.595-2.546,2.35-3.867
    C55.195,47.002,55.898,45.68,56.569,44.378L56.569,44.378z M40.033,61.774c-1.517-1.562-3.034-3.307-4.531-5.215
    c1.465,0.065,2.969,0.117,4.486,0.117c1.53,0,3.047-0.033,4.531-0.117C43.047,58.467,41.53,60.212,40.033,61.774L40.033,61.774z
     M27.904,52.171c-2.318-0.345-4.551-0.768-6.686-1.289c0.605-2.103,1.354-4.271,2.201-6.439c0.671,1.302,1.367,2.611,2.135,3.913
    C26.322,49.665,27.103,50.934,27.904,52.171L27.904,52.171z M39.948,18.239c1.517,1.562,3.034,3.307,4.531,5.215
    c-1.465-0.065-2.969-0.117-4.486-0.117c-1.53,0-3.047,0.033-4.531,0.117C36.934,21.546,38.451,19.801,39.948,18.239L39.948,18.239z
     M27.884,27.842c-0.801,1.257-1.595,2.546-2.35,3.867c-0.749,1.302-1.452,2.611-2.122,3.913c-0.879-2.188-1.628-4.368-2.253-6.491
    C23.301,28.623,25.553,28.187,27.884,27.842L27.884,27.842z M13.125,48.258C7.35,45.798,3.62,42.568,3.62,40.01
    c0-2.559,3.737-5.807,9.505-8.249c1.4-0.605,2.936-1.139,4.518-1.647c0.931,3.197,2.155,6.523,3.672,9.928
    c-1.497,3.392-2.708,6.699-3.62,9.883C16.081,49.417,14.544,48.864,13.125,48.258L13.125,48.258z M21.901,71.559
    c-2.22-1.27-3.177-6.113-2.428-12.344c0.182-1.53,0.475-3.145,0.833-4.792c3.197,0.781,6.686,1.387,10.352,1.777
    c2.201,3.014,4.486,5.755,6.784,8.151c-5.319,4.941-10.306,7.65-13.698,7.65C23.008,71.982,22.389,71.839,21.901,71.559
    L21.901,71.559z M60.579,59.137c0.768,6.23-0.182,11.074-2.383,12.363c-0.488,0.293-1.126,0.423-1.875,0.423
    c-3.372,0-8.379-2.689-13.698-7.598c2.285-2.396,4.564-5.117,6.732-8.138c3.685-0.391,7.174-0.996,10.371-1.797
    C60.104,56.038,60.397,57.62,60.579,59.137L60.579,59.137z M66.855,48.258c-1.4,0.605-2.936,1.139-4.518,1.647
    c-0.931-3.197-2.155-6.523-3.672-9.928c1.497-3.392,2.708-6.699,3.62-9.883c1.615,0.508,3.145,1.061,4.583,1.66
    c5.775,2.461,9.505,5.69,9.505,8.249C76.361,42.568,72.63,45.811,66.855,48.258L66.855,48.258z"/>
</g>
</svg>,
sass:<svg x="0px" y="0px" width="80px" height="80px" viewBox="0 0 80 80"><g>
<path d="M68.868,44.45c-2.799,0.013-5.221,0.69-7.252,1.686c-0.749-1.484-1.497-2.78-1.628-3.75
    c-0.143-1.126-0.312-1.81-0.143-3.158c0.169-1.341,0.951-3.249,0.951-3.405c-0.013-0.143-0.169-0.827-1.784-0.846
    c-1.608-0.013-3.001,0.312-3.158,0.736s-0.469,1.393-0.671,2.389c-0.28,1.471-3.216,6.673-4.889,9.407
    c-0.547-1.061-1.016-1.999-1.107-2.747c-0.143-1.126-0.312-1.81-0.143-3.158c0.169-1.341,0.951-3.249,0.951-3.405
    c-0.013-0.143-0.169-0.827-1.784-0.846c-1.608-0.013-3.001,0.312-3.157,0.736c-0.156,0.423-0.326,1.419-0.671,2.389
    c-0.326,0.97-4.232,9.655-5.247,11.92c-0.514,1.159-0.97,2.077-1.296,2.702s-0.013,0.046-0.046,0.111
    c-0.28,0.534-0.436,0.827-0.436,0.827v0.013c-0.221,0.391-0.456,0.768-0.56,0.768c-0.078,0-0.234-1.048,0.033-2.487
    c0.579-3.014,1.986-7.721,1.966-7.89c0-0.078,0.267-0.905-0.905-1.328c-1.139-0.423-1.549,0.28-1.641,0.28
    s-0.169,0.247-0.169,0.247s1.263-5.299-2.422-5.299c-2.311,0-5.501,2.513-7.077,4.811c-1.003,0.547-3.125,1.706-5.378,2.936
    c-0.859,0.469-1.751,0.97-2.591,1.419l-0.169-0.189C13.973,44.574,5.705,41.201,6.05,34.795c0.124-2.331,0.937-8.47,15.879-15.905
    c12.233-6.094,22.031-4.421,23.737-0.703c2.422,5.312-5.234,15.189-17.955,16.608c-4.844,0.547-7.389-1.328-8.034-2.031
    c-0.671-0.736-0.768-0.768-1.016-0.625c-0.404,0.221-0.156,0.872,0,1.263c0.378,0.983,1.94,2.734,4.596,3.607
    c2.331,0.768,8.014,1.185,14.889-1.471c7.702-2.982,13.717-11.269,11.953-18.19c-1.797-7.038-13.483-9.355-24.531-5.43
    C18.992,14.262,11.863,17.92,6.74,22.719c-6.094,5.684-7.064,10.651-6.66,12.715c1.419,7.363,11.562,12.155,15.625,15.703
    c-0.202,0.111-0.391,0.221-0.56,0.312c-2.031,1.003-9.765,5.045-11.706,9.329c-2.187,4.844,0.345,8.333,2.031,8.802
    c5.221,1.452,10.566-1.159,13.457-5.456c2.878-4.297,2.533-9.876,1.204-12.422l-0.046-0.091l1.595-0.937
    c1.029-0.612,2.044-1.172,2.936-1.654c-0.501,1.361-0.859,2.969-1.048,5.312c-0.221,2.747,0.905,6.315,2.389,7.721
    c0.658,0.612,1.439,0.625,1.921,0.625c1.719,0,2.5-1.419,3.359-3.125c1.061-2.077,1.999-4.486,1.999-4.486
    s-1.172,6.517,2.031,6.517c1.172,0,2.344-1.517,2.878-2.298V59.3c0,0,0.033-0.046,0.091-0.156c0.065-0.098,0.13-0.195,0.189-0.299
    v-0.033c0.469-0.814,1.517-2.669,3.079-5.749c2.018-3.971,3.952-8.939,3.952-8.939s0.189,1.217,0.768,3.216
    c0.345,1.185,1.094,2.487,1.673,3.75c-0.469,0.658-0.749,1.029-0.749,1.029l0.013,0.013c-0.378,0.501-0.794,1.029-1.237,1.562
    c-1.595,1.908-3.503,4.075-3.75,4.7c-0.299,0.736-0.234,1.283,0.345,1.719c0.423,0.312,1.172,0.378,1.966,0.312
    c1.439-0.091,2.435-0.456,2.936-0.671c0.781-0.28,1.673-0.703,2.533-1.328c1.562-1.159,2.513-2.799,2.422-4.987
    c-0.046-1.204-0.436-2.389-0.924-3.516c0.143-0.202,0.28-0.404,0.423-0.625c2.467-3.607,4.375-7.578,4.375-7.578
    s0.189,1.217,0.768,3.216c0.299,1.016,0.892,2.122,1.419,3.216c-2.311,1.888-3.763,4.075-4.264,5.514
    c-0.924,2.656-0.202,3.861,1.159,4.141c0.612,0.124,1.484-0.156,2.142-0.436c0.814-0.267,1.797-0.716,2.702-1.393
    c1.562-1.159,3.06-2.767,2.982-4.935c-0.046-1.003-0.312-1.986-0.671-2.923c1.966-0.814,4.518-1.283,7.747-0.892
    c6.953,0.814,8.327,5.156,8.066,6.966c-0.267,1.81-1.719,2.812-2.2,3.125c-0.482,0.299-0.638,0.404-0.592,0.625
    c0.065,0.326,0.28,0.312,0.703,0.247c0.579-0.091,3.659-1.484,3.782-4.831C80.183,49.092,76.101,44.404,68.868,44.45L68.868,44.45
    L68.868,44.45z M15.242,62.529c-2.298,2.513-5.534,3.47-6.907,2.656c-1.484-0.859-0.905-4.564,1.921-7.233
    c1.719-1.628,3.952-3.125,5.423-4.049c0.326-0.202,0.827-0.501,1.419-0.859c0.091-0.065,0.156-0.091,0.156-0.091
    c0.111-0.065,0.234-0.143,0.358-0.221C18.66,56.546,17.664,59.886,15.242,62.529L15.242,62.529z M32.039,51.11
    c-0.794,1.953-2.487,6.973-3.503,6.686c-0.872-0.234-1.406-4.03-0.169-7.78c0.625-1.888,1.953-4.141,2.734-5.013
    c1.263-1.406,2.643-1.875,2.982-1.296C34.493,44.45,32.54,49.886,32.039,51.11L32.039,51.11z M45.899,57.731
    c-0.345,0.169-0.658,0.299-0.794,0.202c-0.111-0.065,0.143-0.299,0.143-0.299s1.732-1.862,2.422-2.721
    c0.391-0.501,0.859-1.081,1.361-1.732v0.189C49.024,55.622,46.869,57.126,45.899,57.731L45.899,57.731z M56.589,55.296
    c-0.247-0.189-0.221-0.768,0.625-2.578c0.326-0.716,1.081-1.921,2.376-3.06c0.156,0.469,0.247,0.924,0.234,1.341
    C59.805,53.812,57.807,54.86,56.589,55.296L56.589,55.296L56.589,55.296z"/>
</g>
</svg>,
tailwind:<svg x="0px" y="0px" width="80px" height="80px" viewBox="0 0 80 80"><path d="M40,16.143c-10.664,0-17.331,5.302-20,15.905c4.002-5.299,8.668-7.287,13.997-5.964c3.047,0.755,5.208,2.949,7.63,5.378
c3.906,3.958,8.464,8.535,18.372,8.535c10.664,0,17.331-5.302,20-15.905c-3.997,5.304-8.663,7.292-13.997,5.964
c-3.047-0.755-5.208-2.949-7.63-5.378C54.453,20.7,49.915,16.143,40,16.143z M20,39.997c-10.664,0-17.331,5.302-20,15.905
c4.006-5.299,8.672-7.287,13.997-5.964c3.047,0.755,5.208,2.949,7.63,5.384c3.906,3.952,8.464,8.535,18.372,8.535
c10.664,0,17.331-5.302,20-15.905c-3.997,5.299-8.663,7.283-13.997,5.951c-3.047-0.755-5.208-2.949-7.63-5.378
c-3.906-3.958-8.464-8.542-18.372-8.542L20,39.997z"/>
</svg>,
wordpress:<svg x="0px" y="0px" width="80px" height="80px" viewBox="0 0 80 80">
    <g>
	<path d="M40,0C17.91,0,0,17.91,0,40s17.91,40,40,40s40-17.91,40-40S62.09,0,40,0L40,0z M69.251,24.01
		c0.143,1.061,0.221,2.201,0.221,3.424c0,3.379-0.632,7.181-2.533,11.94L56.758,68.809C66.667,63.034,73.333,52.292,73.333,40
		C73.333,34.206,71.855,28.757,69.251,24.01L69.251,24.01z M40.586,42.917l-10,29.062c2.988,0.879,6.146,1.361,9.414,1.361
		c3.88,0,7.604-0.671,11.068-1.888c-0.091-0.143-0.169-0.293-0.241-0.462L40.586,42.917L40.586,42.917z M62.5,38.32
		c0-4.121-1.478-6.973-2.747-9.193c-1.693-2.747-3.275-5.072-3.275-7.819c0-3.066,2.324-5.918,5.599-5.918
		c0.15,0,0.286,0.02,0.43,0.026C56.576,9.98,48.672,6.667,40,6.667c-11.647,0-21.888,5.977-27.852,15.026
		c0.781,0.026,1.517,0.039,2.148,0.039c3.483,0,8.887-0.423,8.887-0.423c1.797-0.104,2.005,2.533,0.215,2.747
		c0,0-1.803,0.208-3.815,0.319l12.135,36.107l7.298-21.875l-5.195-14.232c-1.797-0.104-3.496-0.319-3.496-0.319
		c-1.797-0.104-1.589-2.852,0.208-2.747c0,0,5.501,0.423,8.776,0.423c3.483,0,8.887-0.423,8.887-0.423
		c1.797-0.104,2.005,2.533,0.215,2.747c0,0-1.81,0.208-3.815,0.319l12.044,35.827l3.438-10.898
		C61.608,44.544,62.5,41.172,62.5,38.32L62.5,38.32z M6.667,40c0,13.197,7.669,24.596,18.789,30L9.551,26.432
		C7.702,30.579,6.667,35.163,6.667,40L6.667,40z M40,2.402c5.078,0,10,0.996,14.635,2.956c2.227,0.944,4.375,2.109,6.387,3.464
		c1.992,1.348,3.867,2.891,5.566,4.59c1.699,1.699,3.249,3.574,4.59,5.566c1.361,2.012,2.526,4.16,3.464,6.387
		C76.602,30,77.598,34.922,77.598,40s-0.996,10-2.956,14.635c-0.944,2.227-2.109,4.375-3.464,6.387
		c-1.348,1.992-2.891,3.867-4.59,5.566c-1.699,1.699-3.574,3.249-5.566,4.59c-2.012,1.361-4.16,2.526-6.387,3.464
		C50,76.602,45.078,77.598,40,77.598s-10-0.996-14.635-2.956c-2.227-0.944-4.375-2.109-6.387-3.464
		c-1.992-1.348-3.867-2.891-5.566-4.59c-1.699-1.699-3.249-3.574-4.59-5.566c-1.361-2.012-2.526-4.16-3.464-6.387
		C3.392,50,2.402,45.078,2.402,40s0.996-10,2.956-14.635c0.944-2.227,2.109-4.375,3.464-6.387c1.348-1.992,2.891-3.867,4.59-5.566
		c1.699-1.699,3.574-3.249,5.566-4.59c2.012-1.361,4.16-2.526,6.387-3.464C30,3.392,34.922,2.402,40,2.402L40,2.402z"/>
</g>
</svg>,








}
import { Link, useLocation } from "react-router-dom"

const Nav = () => {

    const location = useLocation();
    const {pathname} = location;
    const items = {
        'Works' : '/',
        'About' : '/about',

    }
    const ret = []
    for (let i in items){
        const cl = pathname === items[i] ? 'bg-slate-100' : ''
        ret.push(<Link className={cl+' rounded-xl px-3 py-1 text-slate-700'} key={i} to={items[i]}>{i}</Link>)
    }

    return <div className='text-sm flex gap-5 font-extrabold px-10'>{ret}</div>;
}

export default Nav